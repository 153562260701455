import React from 'react'
import Particles from 'react-particles-js';
import Progress from 'components/progress'
import { Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faLinkedin, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons'
import ThemeContext from '../../context'
import './styles.scss'

class Hero extends React.Component {

    static contextType = ThemeContext

    render() {
        return (
            <section id={`${this.props.id}`} className="about" style={{height: this.context.height}}>
                {this.particles()}
                <Row>
                    <Col md={6} className="content">
                        <div className="content-text">
                            <div className="line-text">
                                <h4>About Me</h4>
                            </div>
                            <h3>Building something from scratch</h3>
                            <div className="separator" />
                            <p>I'm currently Chief Technology Officer of Pinjam Modal, Pinjam Modal is financial technology company based in Indonesia, I belief that innovative, creative solutions deliver more value and a better experience. Find me on linkedin</p>
                            <div className="social social_icons">
                                <FontAwesomeIcon icon={faLinkedin} className="social_icon" onClick={() => window.open('https://www.linkedin.com/in/ichwan-peryana-8a15571b/?originalSubdomain=id')} />
                            </div>
                        </div>
                    </Col>
                    <Col md={6} className="skills">
                            <div className="line-text">
                                <h4>Expertise</h4>
                            </div>
                            <div className="skills-container">
                                <Progress name="Web Development Process and Methodology" value={100} delay={1100} />
                                <Progress name="Leadership" value={90} delay={1100} />
                                <Progress name="Scalable Architecture" value={90} delay={1100} />
                                <Progress name="Growth Hacking" value={80} delay={1100} />
                                <Progress name="Design" value={90} delay={1100} />
                                <Progress name="Start Up" value={100} delay={1100} />
                            </div>
                    </Col>
                </Row>
            </section>
        )
    }

    particles() {
        return (
            <Particles
                className="particles"
                params={{
                    "particles": {
                        "number": {
                            "value": 50,
                            "density": {
                                "enable": false,
                                "value_area": 5000
                            }
                        },
                        "line_linked": {
                            "enable": true,
                            "opacity": .5
                        },
                        "size": {
                            "value": 1
                        },
                    },
                    "retina_detect": true
            }}/>
        )
    }

}

export default Hero